import {
  scmpPlus,
  theme,
  useResponsive,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import type { AiRecommendedForYouResponse, AiSectionResponse } from "scmp-app/lib/ai-engine/types";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { aiArticleWidgetDisplayQuery } from "scmp-app/queries/__generated__/aiArticleWidgetDisplayQuery.graphql";

import {
  Container,
  SectionArticleItems,
  StyledContentItemHomeSecondary,
  WidgetContainer,
  WidgetTitle,
} from "./styles";

type Props = {
  aiData: AiSectionResponse | AiRecommendedForYouResponse;
};

export const AiArticleWidgetDisplay: FunctionComponent<Props> = ({ aiData }) => {
  const { rec_id: aiRecommendationId, results: aiArticleResults } = aiData;
  const articleEntityIds = aiArticleResults.map(result => result.id) ?? [];

  const { articles } = useLazyLoadQuery<aiArticleWidgetDisplayQuery>(
    graphql`
      query aiArticleWidgetDisplayQuery(
        $entityIds: [String]
        $first: Int!
        $scmpPlusPaywallTypeIds: [String]
      ) {
        articles(
          exclude: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          filter: { entityIds: $entityIds }
          first: $first
        ) {
          edges {
            node {
              ... on Content {
                entityId
                urlAlias
                ...homeSecondaryContentItemContent
              }
            }
          }
        }
      }
    `,
    {
      entityIds: articleEntityIds,
      first: aiArticleResults.length ?? 0,
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
    },
    { fetchPolicy: "store-or-network" },
  );
  const isTablet = useResponsive(theme.breakpoints.only("tablet"), true);
  // there is a width distance between the tablet and home desktop, so need to include an intermediate size
  const isDesktop = useResponsive(theme.breakpoints.only("desktop"), true);
  const articlesToShow = isTablet || isDesktop ? 3 : 5;

  const sortedArticles = useMemo(
    () => [...aiArticleResults].sort((a, b) => a.rank - b.rank).slice(0, articlesToShow),
    [aiArticleResults, articlesToShow],
  );

  const articleMap = useMemo(
    () => Object.fromEntries(articles?.edges?.map(({ node }) => [node.entityId!, node])),
    [articles],
  );

  const articleNodes = useMemo(
    () => sortedArticles.map(result => articleMap[result.id]),
    [articleMap, sortedArticles],
  );

  const listOfArticleIds = sortedArticles.map(article => article.id).join(",");

  const ga4ImpressionEvent = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticleIds,
        page_type: "section",
        recommendation_id: aiRecommendationId,
        widget_name: "recommended_for_you",
      },
      subcategory: "widget",
    }),
    [aiRecommendationId, listOfArticleIds],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const handleClick = useCallback(
    (entityId: string, destinationUrl: string) => {
      sendGA4Tracking({
        action: "click",
        category: "recirculation",
        customized_parameters: {
          article_id: entityId,
          destination_url: destinationUrl,
          list_of_articles: listOfArticleIds,
          page_type: "section",
          recommendation_id: aiRecommendationId,
          widget_name: "recommended_for_you",
        },
        subcategory: "widget",
      });
    },
    [aiRecommendationId, listOfArticleIds],
  );

  return (
    <WidgetContainer ref={captureTrackImpressionEventTargetElement}>
      <WidgetTitle>Recommended For You</WidgetTitle>
      <Container>
        <BaseLinkContextProvider
          customQueryParameters={{
            module: "AI_Recommended_for_you_In-house",
            pgtype: "section",
          }}
        >
          <SectionArticleItems>
            {articleNodes.map(node => (
              <StyledContentItemHomeSecondary
                key={node.entityId}
                reference={node}
                withImage={true}
                withSection={true}
                onClick={() => handleClick(node?.entityId ?? "", getAbsoluteUrl(node.urlAlias))}
              />
            ))}
          </SectionArticleItems>
        </BaseLinkContextProvider>
      </Container>
    </WidgetContainer>
  );
};

AiArticleWidgetDisplay.displayName = "AiArticleWidgetDisplay";
