import { type FunctionComponent } from "react";

import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { AiArticleWidgetDisplay } from "scmp-app/components/section/ai-article-widget/ai-article-widget-display";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";

import { useFetchAiArticleWidgetArticles } from "./hooks";

export type Props = {
  aiEngineDatatype?: AiEngineDataType.Section | AiEngineDataType.HomeRecommendedForYou;
  sectionIds?: string[];
};

export const AiArticleWidget: FunctionComponent<Props> = ({
  aiEngineDatatype = AiEngineDataType.Section,
  sectionIds = [],
}) => {
  const { aiData } = useFetchAiArticleWidgetArticles(aiEngineDatatype, sectionIds);

  return aiData && aiData?.results?.length > 0 ? (
    <ClientSideSuspense>
      <AiArticleWidgetDisplay aiData={aiData} />
    </ClientSideSuspense>
  ) : null;
};

AiArticleWidget.displayName = "AiArticleWidget";
